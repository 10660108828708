import Head from 'next/head'
import { ErrorPage } from '../components/ErrorPage'

export default function Custom404() {
  return (
    <>
      <Head>
        <title>Página no encontrada | Unimarc</title>
        <meta
          content='noindex'
          name='robots'
        />
      </Head>
      <ErrorPage errorType='404' />
    </>
  )
}
